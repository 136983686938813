<!--
 * @Author: your name
 * @Date: 2021-08-04 11:16:38
 * @LastEditTime: 2021-08-17 17:43:11
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\user\admin\components\activity.vue
-->
<template>
  <div class="activity">
    <el-form :inline="true" size="small" style="margin-top: 20px">
      <el-form-item>
        <el-select
          style="width: 100px"
          v-model="activityStatus"
          clearable
          placeholder="请选择活动状态"
        >
          <el-option label="即将开始" value="0"></el-option>
          <el-option label="正在进行" value="1"></el-option>
          <el-option label="已结束" value="2"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-input
          style="width: 180px"
          v-model="activityName"
          placeholder="请输入活动名称搜索"
          clearable
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-date-picker
          style="width: 240px"
          v-model="dateValue"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          value-format="timestamp"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="
            currPage = 1;
            getList();
          "
          >搜索</el-button
        >
      </el-form-item>
      <el-form-item style="float: right">
        <el-button
          type="primary"
          @click="$router.push({ name: 'admin', query: { tab: 41 ,id:$route.query.id} })"
          >发布活动</el-button
        >
      </el-form-item>
    </el-form>

    <el-table
      ref="multipleTable"
      :data="tableData"
      style="width: 100%"
      size="small"
      height="430"
    >
      <el-table-column
        label="序号"
        type="index"
        align="center"
        width="50"
      ></el-table-column>
      <el-table-column
        label="活动名称"
        align="center"
        prop="activityName"
        width="150"
      ></el-table-column>
      <el-table-column
        label="活动日期"
        align="center"
        width="280"
      >
       <template slot-scope="scope">
         {{$formatDate(scope.row.startTime)}} - {{$formatDate(scope.row.endTime)}}
       </template>
      </el-table-column>
      <el-table-column
        label="活动时长(H)"
        align="center"
        prop="longTime"
        width="100"
      >
      <template slot-scope="scope">
         {{scope.row.longTime}}
       </template>
      </el-table-column>
      <el-table-column
        label="报名人数/计划招募人数"
        align="center"
        prop="data4"
        width="150"
      >
      <template slot-scope="scope">
         {{scope.row.numberParticipants}}/{{scope.row.activityMember}}
       </template>
      </el-table-column>

      <el-table-column
        label="活动状态"
        align="center"
        prop="data5"
      >
      <template slot-scope="scope">
        <span v-if="scope.row.status==0">即将开始</span>
         <span v-if="scope.row.status==1">正在进行</span>
         <span v-if="scope.row.status==2">已结束</span>
       </template>
      </el-table-column>

      <el-table-column align="center" label="操作" fixed="right" width="230">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            @click="$router.push({ name: 'admin', query: { tab: 41,id:$route.query.id,activityId:scope.row.activityId } })"
            >编辑活动</el-button
          >
           <el-button
            size="mini"
            type="text"
            @click="$router.push({ name: 'admin', query: { tab: 42,id:$route.query.id,activityId:scope.row.activityId } })"
            >报名审核</el-button
          >
          <el-button
            size="mini"
            type="text"
            @click="$router.push({ name: 'admin', query: { tab: 43,id:$route.query.id,activityId:scope.row.activityId } })"
            >报名人员</el-button
          >
          <el-button @click="del(scope.row)" type="text" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="Pagination flex-h flex-vc">
      <!-- <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="10"
        layout="sizes,total, prev, pager, next"
        :total="total"
      ></el-pagination> -->
      <el-pagination
        size="small"
        background
        @current-change="handleCurrentChange"
        :current-page="currPage"
        :page-size="limit"
        layout=" prev, pager,  next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { activityAdminList,activityDel } from "@/api";
export default {
  name: "group",
  data() {
    return {
      activityName: "",
      activityStatus: "",
      dateValue: [],
      tableData: [],
      limit: 10,
      total: 0,
      currPage: 1,
    };
  },
  components: {},
  methods: {
    del(row) {
      this.$confirm("确定要删除该志愿者吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          activityDel({
            id: row.activityId,
          }).then((res) => {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.getList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    getList() {
      activityAdminList({
        currPage: this.currPage,
        pageLimit: this.limit,
        activityStatus: this.activityStatus,
        activityName: this.activityName,
        queueId:this.$route.query.id,
        startTime: this.dateValue?this.dateValue[0]/1000:'',
        endTime: this.dateValue?this.dateValue[1]/1000:'',
      }).then((res) => {
        console.log(res);
        this.tableData = res.data.list;
        this.total = res.data.totalCount;
      });
    },

    handleCurrentChange(val) {
      this.currPage = val;
      this.getList();
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
</style>