<!--
 * @Author: your name
 * @Date: 2021-08-04 10:51:31
 * @LastEditTime: 2021-08-18 09:38:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\user\admin\index.vue
-->
<template>
  <div class="admin">
    <h1>管理中心</h1>
    <div class="tabs">
      <span
        v-if="checkButtonArray(1)"
        :class="{ active: tab == 1 }"
        @click="
          $router.push({
            name: 'admin',
            query: { tab: 1, id: $route.query.id },
          })
        "
        >首页</span
      >
      <span
        v-if="checkButtonArray(2)"
        :class="{ active: tab == 2 }"
        @click="
          $router.push({
            name: 'admin',
            query: { tab: 2, id: $route.query.id },
          })
        "
        >组员审核</span
      >
      <span
        v-if="checkButtonArray(3)"
        :class="{ active: tab == 3 || tab == 31 }"
        @click="
          $router.push({
            name: 'admin',
            query: { tab: 3, id: $route.query.id },
          })
        "
        >组员管理</span
      >
      <span
        v-if="checkButtonArray(4)"
        :class="{ active: tab == 4 || tab == 41 || tab == 42 || tab == 43 }"
        @click="
          $router.push({
            name: 'admin',
            query: { tab: 4, id: $route.query.id },
          })
        "
        >活动管理</span
      >
    </div>
    <div :is="component"></div>
  </div>
</template>
<script>
import home from "./components/home.vue";
import audit from "./components/audit.vue";
import group from "./components/group.vue";
import groupDetail from "./components/groupDetail.vue";
import activity from "./components/activity.vue";
import editActivity from "./components/editActivity.vue";
import signUp from "./components/signUp.vue";
import activityAudit from "./components/activityAudit.vue";

export default {
  data() {
    return {
      component: "",
    };
  },
  components: {
    home,
    group,
    audit,
    activity,
    groupDetail,
    editActivity,
    signUp,
    activityAudit,
  },
  mounted() {
    // this.$bus.$on("cutadmin", (data) => {
    //   this.component = data;
    // });
  },
  computed: {
    tab: function () {
      return this.$route.query.tab;
    },
  },
  updated() {
    this.change(this.tab);
  },
  created() {
    this.change(this.$route.query.tab);
  },
  methods: {
    change(tab) {
      tab = parseInt(tab);
      switch (tab) {
        case 1:
          this.component = "home";
          break;
        case 2:
          this.component = "audit";
          break;
        case 3:
          this.component = "group";
          break;
        case 31:
          this.component = "groupDetail";
          break;
        case 4:
          this.component = "activity";
          break;
        case 41:
          this.component = "editActivity";
          break;
        case 42:
          this.component = "activityAudit";
          break;
        case 43:
          this.component = "signUp";
          break;
        default:
          break;
      }
    },
    checkButtonArray(id) {
      let buttonList = this.$store.state.privilege || [];
      let bool = false;
      buttonList.forEach((item) => {
        if (item == id) {
          bool = true;
        }
      });
      return bool;
    },
  },
};
</script>
<style lang="scss" scoped>
.admin {
  width: 822px;
  height: 642px;
  background: #fff6f6;
  border-radius: 23px;
  margin: 17px 0 0 15px;
  h1 {
    font-size: 18px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #ff6e6e;
    padding: 15px 0 0 20px;
  }
  .tabs {
    margin-left: 17px;
    font-size: 16px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    // color: #FF6E6E;
    color: #545353;
    margin-top: 20px;
    span {
      margin-right: 55px;
      cursor: pointer;
      &.active {
        color: #ff6e6e;
      }
    }
  }
}
</style>