<template>
  <div class="editActivity">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="130px"
      class="demo-ruleForm"
      size="small"
    >
      <el-form-item label="活动名称" prop="activityName">
        <el-input
          v-model="ruleForm.activityName"
          style="width: 300px"
          maxlength="40"
          show-word-limit
          clearable
          placeholder="请输入活动名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="活动封面" prop="activityImage">
        <el-upload
          class="avatar-uploader"
          :action="`${$baseURL}/file/publicUpload`"
          :headers="headers"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <img
            v-if="ruleForm.activityImage"
            :src="ruleForm.activityImage"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <div slot="tip" class="el-upload__tip">尺寸：16：9</div>
        </el-upload>
      </el-form-item>
      <el-form-item label="活动日期" prop="dateValue">
        <el-date-picker
          v-model="ruleForm.dateValue"
          type="datetimerange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          value-format="timestamp"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="报名截止日期" prop="applicationDeadline">
        <el-date-picker
          v-model="ruleForm.applicationDeadline"
          type="datetime"
          placeholder="选择日期时间"
          value-format="timestamp"
          :picker-options="expireTimeOption"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="活动类型" prop="activityType">
        <el-select v-model="ruleForm.activityType" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.typeName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="活动地点" prop="activityAddress">
        <el-input
          v-model="ruleForm.activityAddress"
          style="width: 300px"
          maxlength="20"
          show-word-limit
          clearable
          placeholder="请输入具体活动地点"
        ></el-input>
      </el-form-item>
      <el-form-item label="计划招募人数" prop="recruitNum">
        <el-input-number
          v-model="ruleForm.recruitNum"
          :min="0"
          :precision="0"
          label="描述文字"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="活动详情" prop="activityInfo">
        <el-input
          style="width: 350px"
          type="textarea"
          placeholder="请输入内容"
          v-model="ruleForm.activityInfo"
          maxlength="500"
          show-word-limit
        >
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="submitForm('ruleForm')"
          v-if="TYPE == 'add'"
          >立即创建</el-button
        >
        <el-button
          type="primary"
          @click="updateForm('ruleForm')"
          v-if="TYPE == 'update'"
          >立即修改</el-button
        >
        <el-button @click="goBack">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  createActivity,
  activityTypeList,
  updateActivity,
  activityInfo,
} from "@/api/index";
import { getToken } from "@/utils/auth";
export default {
  data() {
    return {
      headers: {
        token: getToken(),
      },
      TYPE: "add",
      expireTimeOption: {
        disabledDate(date) {
          //disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
          return date.getTime() < Date.now();
        },
      },
      options: [],
      ruleForm: {
        activityName: "",
        activityImage: "",
        applicationDeadline: "",
        dateValue: [],
        activityType: "",
        activityAddress: "",
        recruitNum: "",
        activityInfo: "",
      },

      props: {
        checkStrictly: true,
        emitPath: false,
        value: "id",
        label: "organizationName",
        children: "list",
      },

      rules: {
        activityName: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
        ],
        activityImage: [
          { required: true, message: "请上传封面", trigger: "blur" },
        ],
        applicationDeadline: [
          { required: true, message: "请选择时间", trigger: "blur" },
        ],
        dateValue: [{ required: true, message: "请选择时间", trigger: "blur" }],
        activityType: [
          { required: true, message: "请选择活动类型", trigger: "change" },
        ],
        activityAddress: [
          { required: true, message: "请输入活动地点", trigger: "blur" },
        ],
        recruitNum: [
          { required: true, message: "请输入人数", trigger: "blur" },
        ],

        activityInfo: [
          { required: true, message: "请输入内容", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    updateForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          try {
            let data = JSON.parse(JSON.stringify(this.ruleForm));
            data.applicationDeadline = data.applicationDeadline / 1000;
            data.startTime = data.dateValue[0] / 1000;
            data.endTime = data.dateValue[1] / 1000;
            data.queueId = this.$route.query.id;
            updateActivity(data).then((res) => {
              console.log(res);
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.goBack();
            });
          } catch (error) {}
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    goBack() {
      this.$router.push({
        name: "admin",
        query: { tab: 4, id: this.$route.query.id },
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          try {
            let data = JSON.parse(JSON.stringify(this.ruleForm));
            data.applicationDeadline = data.applicationDeadline / 1000;
            data.startTime = data.dateValue[0] / 1000;
            data.endTime = data.dateValue[1] / 1000;
            data.queueId = this.$route.query.id;
            createActivity(data).then((res) => {
              console.log(res);
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.goBack();
            });
          } catch (error) {}
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleAvatarSuccess(res, file) {
      this.ruleForm.activityImage = res.data;
    },
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      const isLt2M = file.size / 1024 < 500;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG/PNG 格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 500K!");
        return false;
      }
      return true;
    },
    beforeUpload(file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      const isLt2M = file.size / 1024 < 300;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG/PNG 格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 300K!");
        return false;
      }
      return true;
    },

    getData(id) {
      activityInfo({ id }).then((res) => {
        res.data.dateValue = [];
        this.ruleForm = res.data;
        this.ruleForm.applicationDeadline =
          this.ruleForm.applicationDeadline * 1000;
        this.ruleForm.dateValue = [
          this.ruleForm.startTime * 1000,
          this.ruleForm.endTime * 1000,
        ];
      });
    },
  },

  created() {
    if (this.$route.query.activityId) {
      this.TYPE = "update";
    } else {
      this.TYPE = "add";
    }

    let asyncFun = async () => {
      let data = await activityTypeList();
      this.options = data.data.list;
      if (this.TYPE == "update") {
        this.getData(this.$route.query.activityId);
      }
    };

    asyncFun();
  },
};
</script>
<style lang="scss">
.editActivity {
  margin: 0 auto;
  padding-top: 17px;
  margin-top: 10px;
  background: #fff;
  width: 772px;
  height: 507px;
  background: #ffffff;
  border-radius: 23px;

  .el-form-item {
    margin-bottom: 12px !important;
  }
  .el-upload__tip {
    display: inline-block !important;
    margin-left: 10px;
  }
}
</style>