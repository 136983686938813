<!--
 * @Author: your name
 * @Date: 2021-08-17 17:16:36
 * @LastEditTime: 2021-09-27 11:11:54
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\user\admin\components\audit.vue
-->
<template>
  <div class="audit">
    <el-form :inline="true" size="small" style="margin-top: 20px">
      <el-form-item>
        <el-select v-model="identityType" clearable placeholder="请选择身份">
          <el-option label="学生志愿者" value="2"></el-option>
          <el-option label="会员志愿者" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="auditStatus" clearable placeholder="请选择组织状态">
          <el-option label="待审核" value="0"></el-option>
          <el-option label="已通过" value="1"></el-option>
          <el-option label="已拒绝" value="2"></el-option>
          <el-option label="已退出" value="3"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-input
          v-model="fieldKey"
          placeholder="请输入关键字"
          clearable
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          @click="
            currPage = 1;
            getList();
          "
          >搜索</el-button
        >
      </el-form-item>
    </el-form>

    <el-table
      ref="multipleTable"
      :data="tableData"
      style="width: 100%"
      size="small"
      height="430"
    >
      <el-table-column
        label="序号"
        type="index"
        align="center"
        width="50"
      ></el-table-column>
      <el-table-column
        label="姓名"
        align="center"
        prop="userName"
      ></el-table-column>
      <el-table-column
        label="手机号"
        align="center"
        prop="phone"
        width="140"
      ></el-table-column>
      <el-table-column label="身份" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.identityType == 0">未完善</span>
          <span v-if="scope.row.identityType == 1">注册志愿者</span>
          <span v-if="scope.row.identityType == 2">学生志愿者</span>
          <span v-if="scope.row.identityType == 3">会员志愿者</span>
        </template>
      </el-table-column>
      <el-table-column label="证件类型" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.idnumberType == 1">居民身份证</span>
          <span v-if="scope.row.idnumberType == 2">香港居民身份证</span>
          <span v-if="scope.row.idnumberType == 3">澳门居民身份证</span>
          <span v-if="scope.row.idnumberType == 4">台湾身份证</span>
          <span v-if="scope.row.idnumberType == 5">护照</span>
        </template>
      </el-table-column>

      <el-table-column
        label="证件号"
        align="center"
        prop="idnumber"
        width="200"
      ></el-table-column>
      <el-table-column
        label="申请时间"
        align="center"
        prop="applyTime"
        width="200"
      >
        <template slot-scope="scope">
          {{ $formatDate(scope.row.applyTime) }}
        </template></el-table-column
      >
      <el-table-column
        label="加入组织状态"
        align="center"
        prop="auditStatus"
        width="120"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.auditStatus == 0">待审核</span>
          <span v-if="scope.row.auditStatus == 1">已通过</span>
          <span v-if="scope.row.auditStatus == 2">已拒绝</span>
          <span v-if="scope.row.auditStatus == 3">已退出</span>
          <span v-if="scope.row.auditStatus == 4">删除</span>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="审核" width="120">
        <template slot-scope="scope" v-if="scope.row.auditStatus == 0">
          <el-button
            @click="reject(1, scope.row.applyId)"
            type="text"
            size="small"
            >通过</el-button
          >
          <el-button
            @click="reject(2, scope.row.applyId)"
            type="text"
            size="small"
            >拒绝</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="Pagination flex-h flex-vc">
      <!-- <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="10"
        layout="sizes,total, prev, pager, next"
        :total="total"
      ></el-pagination> -->
      <el-pagination
        size="small"
        background
        @current-change="handleCurrentChange"
        :current-page="currPage"
        :page-size="limit"
        layout=" prev, pager,  next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { auditList, queueOrganizationStatus } from "@/api";
export default {
  name: "audit",
  data() {
    return {
      fieldKey: "",
      auditStatus: "",
      identityType: "",
      tableData: [{}],
      limit: 10,
      total: 0,
      currPage: 1,
    };
  },
  components: {},
  methods: {
    reject(auditStatus, applyId) {
      if (auditStatus == 1) {
        queueOrganizationStatus({
          auditStatus,
          applyId,
        }).then((res) => {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.getList();
        });
      }
      if (auditStatus == 2) {
        this.$prompt("请输入拒绝理由", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputPattern: /^.{1,50}$/,
          inputErrorMessage: "字数范围为1-50",
        })
          .then(({ value }) => {
            queueOrganizationStatus({
              auditStatus,
              applyId,
              auditRemark: value,
            }).then((res) => {
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.getList();
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "取消输入",
            });
          });
      }
    },
    getList() {
      auditList({
        queueId: this.$route.query.id,
        currPage: this.currPage,
        pageLimit: this.limit,
        identityType: this.identityType,
        fieldKey: this.fieldKey,
        auditStatus: this.auditStatus,
      }).then((res) => {
        console.log(res);
        this.tableData = res.data.list;
        this.total = res.data.totalCount;
      });
    },

    handleCurrentChange(val) {
      this.currPage = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.limit = val;
      this.getList();
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
</style>