<!--
 * @Author: your name
 * @Date: 2021-08-04 11:16:02
 * @LastEditTime: 2021-09-23 11:53:25
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\user\admin\components\group.vue
-->
<template>
  <div class="group">
    <el-form :inline="true" size="small" style="margin-top: 20px">
      <el-form-item>
        <el-select v-model="identityType" clearable placeholder="请选择身份">
          <el-option label="学生志愿者" value="2"></el-option>
          <el-option label="会员志愿者" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="auditStatus" clearable placeholder="请选择状态">
          <el-option label="正常" value="0"></el-option>
          <el-option label="已退出" value="1"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-input
          v-model="fieldKey"
          placeholder="请输入关键字"
          clearable
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          @click="
            currPage = 1;
            getList();
          "
          >搜索</el-button
        >
      </el-form-item>
    </el-form>

    <el-table
      ref="multipleTable"
      :data="tableData"
      style="width: 100%"
      size="small"
      height="430"
    >
      <el-table-column
        label="序号"
        type="index"
        align="center"
        width="50"
      ></el-table-column>
      <el-table-column
        label="姓名"
        align="center"
        prop="userName"
      ></el-table-column>
      <el-table-column
        label="手机号"
        align="center"
        prop="phone"
        width="140"
      ></el-table-column>
      <el-table-column label="身份" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.identityType == 0">未完善</span>
          <span v-if="scope.row.identityType == 1">注册志愿者</span>
          <span v-if="scope.row.identityType == 2">学生志愿者</span>
          <span v-if="scope.row.identityType == 3">会员志愿者</span>
        </template>
      </el-table-column>
      <el-table-column label="证件类型" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.idnumberType == 1">居民身份证</span>
          <span v-if="scope.row.idnumberType == 2">香港居民身份证</span>
          <span v-if="scope.row.idnumberType == 3">澳门居民身份证</span>
          <span v-if="scope.row.idnumberType == 4">台湾身份证</span>
          <span v-if="scope.row.idnumberType == 5">护照</span>
        </template>
      </el-table-column>

      <el-table-column
        label="证件号"
        align="center"
        prop="idnumber"
        width="200"
      ></el-table-column>
      <el-table-column
        label="志愿者编号"
        align="center"
        prop="volunteerCode"
        width="200"
      ></el-table-column>
      <el-table-column
        label="服务时长(H)"
        align="center"
        prop="totalServerTime"
        width="100"
      ></el-table-column>
      <el-table-column align="center" label="操作" fixed="right" width="150">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            @click="
              $router.push({
                name: 'admin',
                query: {
                  tab: 31,
                  id: $route.query.id,
                  userId: scope.row.userId,
                  type: scope.row.identityType,
                },
              })
            "
            >详情</el-button
          >
          <el-button @click="del(scope.row)" type="text" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="Pagination flex-h flex-vc">
      <!-- <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="10"
        layout="sizes,total, prev, pager, next"
        :total="total"
      ></el-pagination> -->
      <el-pagination
        size="small"
        background
        @current-change="handleCurrentChange"
        :current-page="currPage"
        :page-size="limit"
        layout=" prev, pager,  next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {queueOrganizationList, queueOrganizationStatus,queueOrganizationDel } from "@/api";
export default {
  name: "group",
  data() {
    return {
      fieldKey: "",
      auditStatus: "",
      identityType: "",
      tableData: [],
      limit: 10,
      total: 0,
      currPage: 1,
    };
  },
  components: {},
  methods: {
    reject(auditStatus, applyId) {
      if (auditStatus == 1) {
             queueOrganizationStatus({
              auditStatus,
              applyId,
             
            }).then((res) => {
              this.$message({
                type: "success",
                message: res.msg,
              });
               this.getList();
            });
      }
      if (auditStatus == 2) {
        this.$prompt("请输入拒绝理由", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputPattern: /^([\u4e00-\u9fa5]|[0-9_a-zA-Z]){1,50}$/,
          inputErrorMessage: "字数范围为1-50",
        })
          .then(({ value }) => {
            queueOrganizationStatus({
              auditStatus,
              applyId,
              auditRemark: value,
            }).then((res) => {
              this.$message({
                type: "success",
                message: res.msg,
              });
               this.getList();
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "取消输入",
            });
          });
      }
    },
    del(row) {
      this.$confirm("确定要删除该志愿者吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          queueOrganizationDel({
            id: row.applyId,
          }).then((res) => {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.getList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    getList() {
      queueOrganizationList({
        queueId: this.$route.query.id,
        currPage: this.currPage,
        pageLimit: this.limit,
        identityType: this.identityType,
        fieldKey: this.fieldKey,
        auditStatus: this.auditStatus,
      }).then((res) => {
        console.log(res);
        this.tableData = res.data.list;
        this.total = res.data.totalCount;
      });
    },

    handleCurrentChange(val) {
      this.currPage = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.limit = val;
      this.getList();
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
</style>