<!--
 * @Author: your name
 * @Date: 2021-08-04 16:30:37
 * @LastEditTime: 2021-09-23 17:50:27
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\user\admin\components\groupDetail.vue
-->
<template>
  <div class="groupDetail">
    <el-row v-if="$route.query.type == 2">
      <el-col :span="12"
        ><el-form label-width="120px" size="mini">
          <el-form-item label="认证身份："> 学生志愿者 </el-form-item>
          <el-form-item label="昵称："> {{ ruleForm.nickname }} </el-form-item>
          <el-form-item label="照片：">
            <el-image
              style="width: 50px; height: 50px"
              :src="ruleForm.headUrl"
              :preview-src-list="[ruleForm.headUrl]"
            >
            </el-image>
          </el-form-item>
          <el-form-item label="证件类型：">
            <span v-if="ruleForm.idType == 1">居民身份证</span>
            <span v-if="ruleForm.idType == 2">香港居民身份证</span>
            <span v-if="ruleForm.idType == 3">澳门居民身份证</span>
            <span v-if="ruleForm.idType == 4">台湾身份证</span>
            <span v-if="ruleForm.idType == 5">护照</span>
          </el-form-item>
          <el-form-item label="证件号码：">
            {{ ruleForm.idNumber }}
          </el-form-item>
          <el-form-item label="志愿者编号：">
            {{ ruleForm.volunteerNo }}
          </el-form-item>
          <el-form-item label="手机号："> {{ ruleForm.phone }} </el-form-item>
          <el-form-item label="学生身份证明：">
            <el-image
              style="width: 50px; height: 50px; margin-right: 10px"
              :src="item"
              :preview-src-list="ruleForm.idImgList"
              v-for="(item, index) in ruleForm.idImgList"
              :key="index"
            >
            </el-image>
          </el-form-item>
          <el-form-item label="性别：">
            <span v-if="ruleForm.sex == 1">男</span>
            <span v-if="ruleForm.sex == 2">女</span>
          </el-form-item>
          <el-form-item label="生日：">
            {{ $formatDate(ruleForm.birthday, "{y}-{m}-{d}") }}
          </el-form-item>
          <el-form-item label="民族："> {{ ruleForm.race }} </el-form-item>
          <el-form-item label="详细地址：">
            {{ ruleForm.messageAddress }}
          </el-form-item>
        </el-form></el-col
      >
      <el-col :span="12"
        ><el-form label-width="120px" size="mini">
          <el-form-item label="监护人身份："> {{ ruleForm.guardianType }} </el-form-item>
          <el-form-item label="监护人姓名："> {{ ruleForm.guardian }} </el-form-item>
          <el-form-item label="监护人电话："> {{ ruleForm.telephone }} </el-form-item>
          <el-form-item label="注册时间："> {{ $formatDate(ruleForm.insertTime) }} </el-form-item>
          <el-form-item label="公益宣言：">
            {{ ruleForm.declaration }}
          </el-form-item>
        </el-form></el-col
      >
    </el-row>
    <el-row v-if="$route.query.type == 3">
      <el-col :span="12"
        ><el-form label-width="120px" size="mini">
          <el-form-item label="认证身份："> 会员志愿者 </el-form-item>
          <el-form-item label="昵称："> {{ ruleForm.nickname }} </el-form-item>
          <el-form-item label="照片：">
            <el-image
              style="width: 50px; height: 50px"
              :src="ruleForm.headUrl"
              :preview-src-list="[ruleForm.headUrl]"
            >
            </el-image>
          </el-form-item>
          <el-form-item label="证件类型：">
            <span v-if="ruleForm.idType == 1">居民身份证</span>
            <span v-if="ruleForm.idType == 2">香港居民身份证</span>
            <span v-if="ruleForm.idType == 3">澳门居民身份证</span>
            <span v-if="ruleForm.idType == 4">台湾身份证</span>
            <span v-if="ruleForm.idType == 5">护照</span>
          </el-form-item>
          <el-form-item label="证件号码：">
            {{ ruleForm.idNumber }}
          </el-form-item>
          <el-form-item label="志愿者编号：">
            {{ ruleForm.volunteerNo }}
          </el-form-item>
          <el-form-item label="手机号："> {{ ruleForm.phone }} </el-form-item>

          <el-form-item label="性别：">
            <span v-if="ruleForm.sex == 1">男</span>
            <span v-if="ruleForm.sex == 2">女</span>
          </el-form-item>
          <el-form-item label="生日：">
            {{ $formatDate(ruleForm.birthday, "{y}-{m}-{d}") }}
          </el-form-item>
          <el-form-item label="民族："> {{ ruleForm.race }} </el-form-item>
          <el-form-item label="政治面貌：">
            {{ ruleForm.politicCountenance }}
          </el-form-item>
          <el-form-item label="健康状况：">
            {{ ruleForm.health }}
          </el-form-item>
          <el-form-item label="电子邮箱："> {{ ruleForm.email }} </el-form-item>
        </el-form></el-col
      >
      <el-col :span="12"
        ><el-form label-width="120px" size="mini">
          <el-form-item label="详细地址：" style="height:60px;overflow-y:auto">
            {{ ruleForm.messageAddress }}
          </el-form-item>
          <el-form-item label="电话："> {{ ruleForm.telephone }} </el-form-item>
          <el-form-item label="注册时间：">
            {{ $formatDate(ruleForm.insertTime) }}
          </el-form-item>
          <el-form-item label="公益宣言：">
            {{ ruleForm.declaration }}
          </el-form-item>
          <el-form-item label="现工作单位：">
            {{ ruleForm.companyName }}
          </el-form-item>
          <el-form-item label="职务/职称：">
            {{ ruleForm.position }}
          </el-form-item>
          <el-form-item label="工作单位性质：">
            <span v-if="ruleForm.companyNature == 1">政府机关单位</span>
            <span v-if="ruleForm.companyNature == 2">事业单位和国有企业</span>
            <span v-if="ruleForm.companyNature == 3">外资企业</span>
            <span v-if="ruleForm.companyNature == 4">私营企业</span>
            <span v-if="ruleForm.companyNature == 5">社会团体</span>
            <span v-if="ruleForm.companyNature == 6">境外机构</span>
            <span v-if="ruleForm.companyNature == 7">其他</span>
          </el-form-item>
          <el-form-item label="从事何种职业：">
            {{ ruleForm.occupation }}
          </el-form-item>
          <el-form-item label="有何技能特长：" style="height:60px;overflow-y:auto">
            {{ ruleForm.specialty }}
          </el-form-item>
          <el-form-item label="志愿服务意向：" style="height:60px;overflow-y:auto">
            {{ ruleForm.intention }}
          </el-form-item>
        </el-form></el-col
      >
    </el-row>
    <div
      class="btn"
      @click="
        $router.push({ name: 'admin', query: { tab: 3, id: $route.query.id } })
      "
    >
      返回
    </div>
  </div>
</template>
<script>
import { memberInfo, studentInfo } from "@/api/index";
export default {
  data() {
    return {
      ruleForm: {},
    };
  },
  computed: {
    affirm() {
      return this.$store.state.affirm;
    },
  },
  created() {
    if (this.$route.query.type == 3) {
      memberInfo({
        encryptionId: this.$route.query.userId,
      }).then((res) => {
        this.ruleForm = res.data;
        this.ruleForm.birthday = this.ruleForm.birthday;
        this.ruleForm.headUrl = this.ruleForm.visualHeadImg;
        this.headImg = this.ruleForm.privateHeadImg;
        let nativeAddress = this.ruleForm.nativeAddress || {};
        let liveAddress = this.ruleForm.liveAddress || {};
        this.ruleForm.location = [
          nativeAddress.province,
          nativeAddress.city,
          nativeAddress.area,
        ];
        this.ruleForm.address = nativeAddress.address;
        this.ruleForm.message = [
          liveAddress.province,
          liveAddress.city,
          liveAddress.area,
        ];
        this.ruleForm.messageAddress = liveAddress.address;
        this.ruleForm.zmUrl = this.ruleForm.idPositiveImg;
        this.idPositiveImg = this.ruleForm.privateIdPositiveImg;
        this.ruleForm.bmUrl = this.ruleForm.idSideImg;
        this.idSideImg = this.ruleForm.privateIdSideImg;
      });
    }
    if (this.$route.query.type == 2) {
      studentInfo({
        encryptionId: this.$route.query.userId,
      }).then((res) => {
        
        this.ruleForm = res.data;
        let liveAddress = this.ruleForm.liveAddress || {};
        this.ruleForm.birthday = this.ruleForm.birthday;
        this.ruleForm.headUrl = this.ruleForm.visualHeadImg;
        this.headImg = this.ruleForm.privateHeadImg;
        this.ruleForm.messageAddress = liveAddress.address;
       
     
      });
    }
  },
};
</script>
<style lang="scss">
.groupDetail {
  padding-top: 10px;
  .el-form-item {
    margin-bottom: 7px !important;
    word-break:break-all;
  }
  .btn {
    width: 140px;
    height: 40px;
    border-radius: 24px;
    border: 1px solid #ff6e6e;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    font-size: 16px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #ff6e6e;
    margin: 0 auto;
    margin-top: 5px;
  }
}
</style>