<!--
 * @Author: your name
 * @Date: 2021-08-05 10:15:52
 * @LastEditTime: 2021-08-17 17:52:16
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\user\admin\components\apply.vue
-->
<template>
  <div class="signUp">
    <div class="signUpHeader flex-h flex-vc">
      <h1>活动名称：{{ activityName }}</h1>
      <div>
        <el-button
          type="primary"
          size="small"
          @click="getImg();uploadDialogVisible = true"
          >上传本次活动照片</el-button
        >
        <el-button size="small" @click="getImg();centerDialogVisible = true"
          >预览</el-button
        >
      </div>
    </div>
    <div class="divider"></div>
    <div class="small">
      <span>活动时长：{{ longTime }}H</span>
      <span>计划招募人数：{{ activityMember }}</span>
      <span>报名人数：{{ numberParticipants }}</span>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      style="width: 100%"
      size="small"
      height="370"
    >
      <el-table-column
        label="序号"
        type="index"
        align="center"
        width="50"
      ></el-table-column>
      <el-table-column
        label="报名人员"
        align="center"
        prop="applyName"
      ></el-table-column>
      <el-table-column
        label="手机号"
        align="center"
        prop="phone"
        width="140"
      ></el-table-column>
      <el-table-column
        label="志愿者编号"
        align="center"
        prop="volunteerCode"
        width="150"
      ></el-table-column>
    

      <!-- <el-table-column align="center" label="审核" width="120">
        <template slot-scope="scope" v-if="scope.row.status == 0">
          <el-button
            @click="reject(scope.row.applyId, 1)"
            type="text"
            size="small"
            >通过</el-button
          >
          <el-button
            @click="reject(scope.row.applyId, 0)"
            type="text"
            size="small"
            >拒绝</el-button
          >
        </template>
      </el-table-column> -->
      <el-table-column
        label="服务时长(H)"
        align="center"
        prop="longTime"
        width="100"
      ></el-table-column>

      <el-table-column align="center" label="时长确认" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.confireStatus == 1">已确认</span>
          <el-button
            v-if="scope.row.confireStatus == 0"
            @click="send(scope.row)"
            type="text"
            size="small"
            >点击确认</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="Pagination flex-h flex-vc">
      <!-- <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="10"
        layout="sizes,total, prev, pager, next"
        :total="total"
      ></el-pagination> -->
      <el-pagination
        size="small"
        background
        @current-change="handleCurrentChange"
        :current-page="currPage"
        :page-size="limit"
        layout=" prev, pager,  next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <el-dialog
      title="预览"
      :visible.sync="centerDialogVisible"
      width="800px"
      :modal-append-to-body="false"
      :append-to-body="true"
      center
    >
      <div class="imgBox">
        <img
        v-for="(item,index) in liveStreamRemarkImgs"
        :key="index"
          :src="item.url"
          style="display: block; max-width: 100%"
        />
      </div>
    </el-dialog>

    <el-dialog
      title="上传活动照片"
      :visible.sync="uploadDialogVisible"
      width="500px"
      :modal-append-to-body="false"
      :append-to-body="true"
      center
    >
      <el-upload
        list-type="picture-card"
        :action="`${$baseURL}/file/publicUpload`"
        :headers="headers"
        :on-success="handleSuccess"
        :on-remove="handleRemove"
        :file-list="liveStreamRemarkImgs"
        :before-upload="beforeUpload"
        :limit="12"
      >
        <i class="el-icon-plus"></i>
        <div slot="tip" class="el-upload__tip">
          最多上传12张，支持格式为jpg\png\jpeg的图片
        </div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="uploadImg">上传照片</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  simpleActivityInfo,
  recruitList,
  activityConfirm,
  activityImgSave,
  activityImgs,
} from "@/api/index";
import { getToken } from "@/utils/auth";
export default {
  name: "group",
  data() {
    return {
      headers: {
        token: getToken(),
      },
      activityName: "",
      longTime: 0,
      activityMember: 0,
      numberParticipants: 0,
      tableData: [],
      limit: 10,
      total: 0,
      currPage: 1,
      centerDialogVisible: false,
      uploadDialogVisible: false,
      liveStreamRemarkImgs: [],
      dialogImageUrl: "",
      dialogVisible: false,
    };
  },
  components: {},
  methods: {
    beforeUpload(file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      const isLt2M = file.size / 1024 < 300;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG/PNG 格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 300K!");
        return false;
      }
      return true;
    },
    handleSuccess(res, file, fileList) {
      console.log(res);
      this.liveStreamRemarkImgs.push({
        url: res.data,
      });
    },
    handleRemove(file, fileList) {
      var _tmp = this.liveStreamRemarkImgs;
      for (var i = 0, len = _tmp.length; i < len; i++) {
        if (_tmp[i].url == file.url) {
          _tmp.splice(i, 1);
          break;
        }
      }
      this.liveStreamRemarkImgs = _tmp;
    },
    uploadImg() {
      let imgList = this.liveStreamRemarkImgs.map((item) => {
        return item.url;
      });
      activityImgSave({
        proveUrl: imgList,
        activityId: this.$route.query.activityId,
      }).then((res) => {
        this.$message({
          type: "success",
          message: res.msg,
        });
        this.uploadDialogVisible = false
      });
    },
    getImg() {
      activityImgs({
        id: this.$route.query.activityId,
      }).then((res) => {
        this.liveStreamRemarkImgs=res.data.map(item=>{
          return{
            url:item
          }
        })
      });
    },
    send(row) {
      this.$confirm("确定要为该志愿者累加本次活动的服务时长吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        activityConfirm({
          applyId: row.applyId,
          confirmStatus: 1,
        }).then((res) => {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.getList();
        });
      });
    },
    getList() {
      recruitList({
        currPage: this.currPage,
        pageLimit: this.limit,
        activityId: this.$route.query.activityId,
      }).then((res) => {
        console.log(res);
        this.tableData = res.data.list;
        this.total = res.data.totalCount;
      });
    },
    getData() {
      simpleActivityInfo({ id: this.$route.query.activityId }).then((res) => {
        this.activityName = res.data.activityName;
        this.longTime = res.data.longTime;
        this.activityMember = res.data.activityMember;
        this.numberParticipants = res.data.numberParticipants;
      });
    },
    handleCurrentChange(val) {
      this.currPage = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.limit = val;
      this.getList();
    },
  },
  created() {
    this.getData();
    this.getList();
  },
};
</script>
<style lang="scss" scoped>
.signUp {
  width: 786px;
  height: 525px;
  background: #ffffff;
  border-radius: 23px;
  margin: 0 auto;
  margin-top: 15px;
  box-sizing: border-box;
  padding: 15px 20px;
  .signUpHeader {
    justify-content: space-between;
    h1 {
      text-align: center;
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #595656;
    }
  }
  .divider {
    width: 733px;
    height: 2px;
    background: rgba(205, 205, 205, 1);
    margin-top: 12px;
  }
  .small {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #595656;
    margin-top: 13px;
    margin-bottom: 15px;
    span {
      margin-right: 47px;
    }
  }
}
</style>