var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin"},[_c('h1',[_vm._v("管理中心")]),_c('div',{staticClass:"tabs"},[(_vm.checkButtonArray(1))?_c('span',{class:{ active: _vm.tab == 1 },on:{"click":function($event){return _vm.$router.push({
          name: 'admin',
          query: { tab: 1, id: _vm.$route.query.id },
        })}}},[_vm._v("首页")]):_vm._e(),(_vm.checkButtonArray(2))?_c('span',{class:{ active: _vm.tab == 2 },on:{"click":function($event){return _vm.$router.push({
          name: 'admin',
          query: { tab: 2, id: _vm.$route.query.id },
        })}}},[_vm._v("组员审核")]):_vm._e(),(_vm.checkButtonArray(3))?_c('span',{class:{ active: _vm.tab == 3 || _vm.tab == 31 },on:{"click":function($event){return _vm.$router.push({
          name: 'admin',
          query: { tab: 3, id: _vm.$route.query.id },
        })}}},[_vm._v("组员管理")]):_vm._e(),(_vm.checkButtonArray(4))?_c('span',{class:{ active: _vm.tab == 4 || _vm.tab == 41 || _vm.tab == 42 || _vm.tab == 43 },on:{"click":function($event){return _vm.$router.push({
          name: 'admin',
          query: { tab: 4, id: _vm.$route.query.id },
        })}}},[_vm._v("活动管理")]):_vm._e()]),_c(_vm.component,{tag:"div"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }