<!--
 * @Author: your name
 * @Date: 2021-08-04 11:14:40
 * @LastEditTime: 2021-10-13 17:06:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\user\admin\components\home.vue
-->
<template>
  <div class="admin_home">
    <div class="title flex-h flex-vc">
      <span></span>
      <span>数据统计</span>
    </div>
    <div class="tags flex-h flex-hc">
      <div class="tag">
        <img src="@/assets/img/team1.png" />
        <p>{{ queueNum }}人</p>
        <p>队员人数</p>
      </div>
      <div class="tag">
        <img src="@/assets/img/team2.png" />
        <p>{{ activityNum }}次</p>
        <p>发起志愿活动</p>
      </div>
      <div class="tag">
        <img src="@/assets/img/team3.png" />
        <p>{{ serviceLongTime == null ? 0 : serviceLongTime }}小时</p>
        <p>服务时长</p>
      </div>
    </div>
    <div class="title flex-h flex-vc" style="margin-top: 80px" v-if="state==1">
      <span></span>
      <span>快捷设置</span>
    </div>
    <div class="fast flex-h" v-if="state==1">
      <div class="tag">
        <img src="@/assets/img/a1.png" @click="dialogFormVisible = true" />
        <p>更换负责人</p>
      </div>
      <div class="tag" @click="dialogAuthVisible = true">
        <img src="@/assets/img/a2.png" />
        <p>设置权限</p>
      </div>
      <div class="tag" @click="dialogDescVisible = true">
        <img src="@/assets/img/a3.png" />
        <p>解散组织</p>
      </div>
      <div class="tag"  @click="$router.push({name:'organization',query:{id:$route.query.id}})">
        <img src="@/assets/img/a4.png" />
        <p>编辑服务队</p>
      </div>
    </div>
    <el-dialog
      title="更换负责人"
      :visible.sync="dialogFormVisible"
      center
      width="400px"
      :modal-append-to-body="false"
      :append-to-body="true"
    >
      <el-form :model="form">
        <p style="margin-bottom: 15px">
          说明：更换的负责人必须是本服务队的会员志愿者
        </p>
        <el-form-item label="选择新负责人">
          <el-select v-model="form.region" placeholder="请选择">
            <el-option
              :label="item.userName"
              :value="item.userId"
              v-for="(item, index) in option1"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="switchPerson">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="设置权限"
      :visible.sync="dialogAuthVisible"
      center
      width="450px"
      :modal-append-to-body="false"
      :append-to-body="true"
    >
      <el-form :model="authForm">
        <p style="margin-bottom: 15px">
          说明：可以为同服务队的会员志愿者赋予管理中心的菜单权限
        </p>
        <el-form-item label="选择管理员">
          <el-select
            v-model="authForm.region"
            @change="changeValue"
            placeholder="请选择"
          >
            <el-option
              :label="item.userName"
              :value="item.userId"
              v-for="(item, index) in option2"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择权限">
          <el-tree
            style="display: inline-block"
            :data="data"
            show-checkbox
            node-key="id"
            :props="defaultProps"
            ref="tree"
          >
          </el-tree>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogAuthVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="申请解散"
      :visible.sync="dialogDescVisible"
      center
      width="400px"
      :modal-append-to-body="false"
      :append-to-body="true"
    >
      <el-form :model="descForm">
        <el-form-item label="申请解散">
          <el-input
            type="textarea"
            maxlength="100"
            show-word-limit
            :rows="5"
            placeholder="请输入解散服务队的理由"
            v-model="descForm.desc"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogDescVisible = false">取 消</el-button>
        <el-button type="primary" @click="dissolve">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  adminInfo,
  queueUserList,
  setPrivilege,
  privilegeInfo,
  changeAdmin,
  dissolve,
} from "@/api/index";
export default {
  data() {
    return {
      queueNum: 0,
      activityNum: 0,
      serviceLongTime: 0,
      state:'',
      dialogFormVisible: false,
      dialogAuthVisible: false,
      option1: [],
      option2: [],
      form: {
        region: "",
      },

      authForm: {
        region: "",
      },
      data: [
        {
          id: 1,
          label: "首页",
        },
        {
          id: 2,
          label: "组员审核",
        },
        {
          id: 3,
          label: "组员管理",
        },
        {
          id: 4,
          label: "活动管理",
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
      dialogDescVisible: false,
      descForm: {
        desc: "",
      },
    };
  },
  methods: {
    getData() {
      adminInfo({
        id: this.$route.query.id,
      }).then((res) => {
        this.queueNum = res.data.queueNum;
        this.activityNum = res.data.activityNum;
        this.serviceLongTime = res.data.serviceLongTime;
        this.state=res.data.state;
      });
    },
    dissolve() {
      dissolve({
        dissolveRemark: this.descForm.desc,
        queueId: this.$route.query.id,
      }).then((res) => {
        this.dialogDescVisible = false;
        this.$alert("您的申请已提交，请耐心等待审核。", "申请解散", {
          confirmButtonText: "确定",
        });
      });
    },
    submit() {
      let functionIds = this.$refs.tree.getCheckedKeys();
      setPrivilege({
        userId: this.authForm.region,
        functionIds,
      }).then((res) => {
        console.log(res);
        this.dialogAuthVisible = false;
        this.$message({
          type: "success",
          message: res.msg,
        });
      });
    },
    switchPerson() {
      changeAdmin({
        queueId: this.$route.query.id,
        userId: this.form.region,
      }).then((res) => {
        this.dialogFormVisible = false;
        this.getData();
        this.$message({
          type: "success",
          message: res.msg,
        });
      });
    },
    getOption1() {
      queueUserList({
        id: this.$route.query.id,
        state:1
      }).then((res) => {
        this.option1 = res.data;
      });
    },
    getOption2() {
      queueUserList({
        id: this.$route.query.id,
        state:2
      }).then((res) => {
        this.option2 = res.data;
      });
    },
    changeValue(val) {
      this.$refs.tree.setCheckedNodes([]);
      privilegeInfo({ id: val }).then((res) => {
        let list = res.data.functionIds;
        list.forEach((item) => {
          this.$refs.tree.setChecked(item, true);
        });
      });
    },
  },
  created() {
    this.getOption1();
    this.getOption2();
    this.getData();
  },
};
</script>
<style lang="scss" scoped>
.admin_home {
  padding-top: 35px;
  .title {
    margin-left: 25px;
    span {
      &:nth-of-type(1) {
        margin-right: 8px;
        width: 5px;
        height: 19px;
        background: #ff6f6f;
        border-radius: 3px;
      }
      &:nth-of-type(2) {
        font-size: 16px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #423e3e;
      }
    }
  }
  .tags {
    margin-top: 57px;
    .tag {
      margin: 0 70px;
      img {
        width: 60px;
        display: block;
        margin: 0 auto;
      }
      p {
        text-align: center;

        &:nth-of-type(1) {
          font-size: 18px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #242424;
          margin-top: 5px;
          margin-bottom: 2px;
        }
        &:nth-of-type(2) {
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: #242424;
        }
      }
    }
  }
  .fast {
    margin-top: 40px;
    .tag {
      width: 90px;
      margin: 0 25px;
      cursor: pointer;
      img {
        width: 34px;
        display: block;
        margin: 0 auto;
      }
      p {
        margin-top: 7px;
        text-align: center;

        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #6b6464;
      }
    }
  }
}
</style>