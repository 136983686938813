<!--
 * @Author: your name
 * @Date: 2021-08-17 17:40:55
 * @LastEditTime: 2021-08-18 11:29:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\user\admin\components\activityaa.vue
-->
<template>
  <div class="signUp">
    <div class="signUpHeader flex-h flex-vc">
      <h1>活动名称：{{ activityName }}</h1>
    </div>
    <div class="divider"></div>
    <div class="small">
      <span>活动时长：{{ longTime }}H</span>
      <span>计划招募人数：{{ activityMember }}</span>
      <span>报名人数：{{ numberParticipants }}</span>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      style="width: 100%"
      size="small"
      height="370"
    >
      <el-table-column
        label="序号"
        type="index"
        align="center"
        width="50"
      ></el-table-column>
      <el-table-column
        label="报名人员"
        align="center"
        prop="applyName"
      ></el-table-column>
      <el-table-column
        label="手机号"
        align="center"
        prop="phone"
        width="140"
      ></el-table-column>
      <el-table-column
        label="志愿者编号"
        align="center"
        prop="volunteerCode"
        width="150"
      ></el-table-column>
      <el-table-column
        label="审核状态"
        align="center"
        prop="schoolArea"
        width="100"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.status == 0">待审核</span>
          <span v-if="scope.row.status == 1">已通过</span>
          <span v-if="scope.row.status == 4">已拒绝</span>
        </template>
      </el-table-column>

      <el-table-column align="center" label="审核" width="120">
        <template slot-scope="scope" v-if="scope.row.status == 0">
          <el-button
            @click="reject(scope.row.applyId, 1)"
            type="text"
            size="small"
            >通过</el-button
          >
          <el-button
            @click="reject(scope.row.applyId, 0)"
            type="text"
            size="small"
            >拒绝</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="Pagination flex-h flex-vc">
      <!-- <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="10"
        layout="sizes,total, prev, pager, next"
        :total="total"
      ></el-pagination> -->
      <el-pagination
        size="small"
        background
        @current-change="handleCurrentChange"
        :current-page="currPage"
        :page-size="limit"
        layout=" prev, pager,  next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import {
  simpleActivityInfo,
  activityAuditList,
  recruitAudit,
} from "@/api/index";
export default {
  name: "group",
  data() {
    return {
    
      activityName: "",
      longTime: 0,
      activityMember: 0,
      numberParticipants: 0,
      tableData: [],
      limit: 10,
      total: 0,
      currPage: 1,
      liveStreamRemarkImgs: [],
    };
  },
  components: {},
  methods: {
    reject(applyId, status) {
      this.$confirm(`是否${status == 1 ? "通过" : "拒绝"}该审核？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        recruitAudit({
          applyId,
          status,
        }).then((res) => {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.getData();
          this.getList();
        });
      });
    },
    getList() {
      activityAuditList({
        currPage: this.currPage,
        pageLimit: this.limit,
        activityId: this.$route.query.activityId,
      }).then((res) => {
        console.log(res);
        this.tableData = res.data.list;
        this.total = res.data.totalCount;
      });
    },
    getData() {
      simpleActivityInfo({ id: this.$route.query.activityId }).then((res) => {
        this.activityName = res.data.activityName;
        this.longTime = res.data.longTime;
        this.activityMember = res.data.activityMember;
        this.numberParticipants = res.data.numberParticipants;
      });
    },
    handleCurrentChange(val) {
      this.currPage = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.limit = val;
      this.getList();
    },
  },
  created() {
    this.getData();
    this.getList();
  },
};
</script>
<style lang="scss" scoped>
.signUp {
  width: 786px;
  height: 525px;
  background: #ffffff;
  border-radius: 23px;
  margin: 0 auto;
  margin-top: 15px;
  box-sizing: border-box;
  padding: 15px 20px;
  .signUpHeader {
    justify-content: space-between;
    h1 {
      text-align: center;
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #595656;
    }
  }
  .divider {
    width: 733px;
    height: 2px;
    background: rgba(205, 205, 205, 1);
    margin-top: 12px;
  }
  .small {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #595656;
    margin-top: 13px;
    margin-bottom: 15px;
    span {
      margin-right: 47px;
    }
  }
}
</style>